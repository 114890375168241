<template>
  <a-card :bordered='false'>
      <a-row class="query-row" type='flex' :gutter="12">
        <a-col style="width:240px">
           <span>用户手机号：</span>
           <a-input v-model="queryData.mobile" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:200px">
           <span>用户等级：</span>
           <a-select v-model="queryData.userLevel" allowClear placeholder='请选择'>
               <a-select-option value="0">小汇同学</a-select-option>
               <a-select-option value="1">小汇推介官</a-select-option>
               <a-select-option value="2">小汇俱乐部</a-select-option>
               <a-select-option value="3">小汇服务商</a-select-option>
            </a-select>
        </a-col>
        <a-col style="width:200px">
           <span>用户ID：</span>
           <a-input v-model="queryData.unionId" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:220px">
           <span>直属上级：</span>
           <a-input v-model="queryData.parentUserId" allowClear placeholder='请输入'></a-input>
        </a-col>
        <a-col style="width:220px">
           <span>激活情况：</span>
           <a-select v-model="queryData.activeStatus" allowClear placeholder='请选择'>
               <a-select-option value="1">已激活</a-select-option>
               <a-select-option value="0">未激活</a-select-option>
               <a-select-option value="2">晋级中</a-select-option>
               <a-select-option value="3">准晋级</a-select-option>
            </a-select>
        </a-col>
        <a-col style="width:410px">
            <span>准晋级时间：</span>
            <a-range-picker 
            v-model="currentPushTime"
            :show-time="showTime" 
            />
         </a-col>
         <a-col style="width:410px">
            <span>激活时间：</span>
            <a-range-picker 
            v-model="activeTime"
            :show-time="showTime" 
            />
         </a-col>
         <a-col style="width:410px">
            <span>晋级时间：</span>
            <a-range-picker 
            v-model="pushTime"
            :show-time="showTime" 
            />
         </a-col>
         
        <a-col>
           <a-button @click="() => {this.pageNum = 1;this.loadTableData()}" type='primary'>查询</a-button>
           <a-button type='primary' style="margin-left:12px" @click="exportData" v-auth='"userList:export"'>导出</a-button>
        </a-col>
      </a-row>
      <a-table :columns="columns"  :dataSource="tableData" :pagination="false"
             :loading="loading"
             :scroll="{x: true}"
             size="default">
            
         <template slot="options" slot-scope="text, record">
            <a style="margin-bottom:4px;display:block" @click="unbind(record)" v-if="record.nodeStatus == 2" v-auth='"userList:updateNodeStatus"'>解绑</a>
            <a style="display:block" @click="getChangeRecord(record)" v-auth='"userList:changeRecord"'>查看上级变更历史</a>
         </template>
      </a-table>
    <a-pagination
         style="display: flex; flex-direction: row; justify-content: flex-end; margin:10px 10px 5px 0"
         size="small"
         v-model="pageNum"
         :total="total"
         :showTotal="total => `共 ${showTotal} 条`"
         showLessItems
         :pageSize.sync="pageSize"
         :pageSizeOptions='["50"]'
         @change="loadTableData"
         @showSizeChange="() => {this.pageNum = 1; this.loadTableData()}"
      />

      <!-- 查看上级变更历史弹窗 -->
      <a-modal v-drag-modal :destroyOnClose='true' v-model="parentChangeModal" :footer='null' title='直属上级变更历史' >
         <a-table :columns="parentChangeColumns" :dataSource="parentChangeData" :pagination="false"
             :scroll="{x: true}"
             size="default">
         </a-table>
      </a-modal>

      <!--导出modal-->
    <ExportModal
      ref="exportModal"
      :exportUrl="exportUrl"
      :params="handleParams()"
      :total="showTotal"
    >
    </ExportModal>
  </a-card>
</template>

<script>
import moment from 'moment'
import api from '@/api'
import util from '@/libs/util'
import ExportModal from '@/components/ExportModal'

export default {
   name:'UserList',
   components: {
      ExportModal
   },
   data () {
      return {
         exportUrl: api.shopkeeperManage.exportUserList,
         pageNum:1,
         total:0,
         pageSize:50,
         loading:false,
         parentChangeModal: false,
         showTotal:0,
         columns: util.filterAuthColumns([
            {
               title: '序号',
               dataIndex: 'no',
               align: 'center',
               ellipsis:true,
               customRender: (text, record, index) => {
                  return index + 1
               }
            },
            {
               title: '用户ID',
               dataIndex: 'unionId',
               align: 'center',
               ellipsis:true
            },
            {
               title: '小汇ID',
               dataIndex: 'id',
               align: 'center',
               ellipsis:true
            },
            {
               title: '用户手机号',
               dataIndex: 'mobile',
               align: 'center',
               ellipsis:true
            },
            {
               title: '用户角色',
               dataIndex: 'roleStr',
               align: 'center',
               ellipsis:true
            },
            {
               title: '用户等级',
               dataIndex: 'userLevel',
               align: 'center',
               ellipsis:true,
               customRender: (text,record) => {
                  return ['小汇同学','小汇推介官','小汇俱乐部','小汇服务商'][text] || ''
               }
            },
            {
               title: '激活情况',
               dataIndex: 'activeStatusStr',
               align: 'center',
               ellipsis:true,
            },
            {
               title: '晋级时间',
               dataIndex: 'pushTime',
               align: 'center',
               ellipsis: true,
               customRender: (text, {activeStatusStr}) => {
                  if (activeStatusStr === '晋级中') {
                     return ''
                  }
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
               }
            },
            {
               title: '激活时间',
               dataIndex: 'activeTime',
               align: 'center',
               ellipsis: true,
               customRender: (text, {activeStatusStr}) => {
                  if (activeStatusStr === '晋级中') {
                     return ''
                  }
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
               }
            },
            {
               title: '准晋级时间',
               dataIndex: 'currentPushTime',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
               }
            },
            {
               title: '准激活时间',
               dataIndex: 'currentActiveTime',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
               }
            },
            {
               title: '激活积分',
               dataIndex: 'activeJifen',
               align: 'center',
               ellipsis:true,
               customRender: (text, record) => {
                  let {activeJifenEd, activeJifenIng} = record
                  return (
                     <div>
                        <div>总积分：{text || 0}</div>
                        <div>已到账：{activeJifenEd || 0}</div>
                        <div>未到账：{activeJifenIng || 0}</div>
                     </div>
                  )
               }
            },
            {
               title: '奖励积分',
               dataIndex: 'rewardJifen',
               align: 'center',
               ellipsis:true,
               customRender: (text, record) => {
                  let {rewardJifenEd, rewardJifenIng} = record
                  return (
                     <div>
                        <div>总积分：{text || 0}</div>
                        <div>已到账：{rewardJifenEd || 0}</div>
                        <div>未到账：{rewardJifenIng || 0}</div>
                     </div>
                  )
               }
            },
            {
               title: '晋级积分',
               dataIndex: 'pushJifen',
               align: 'center',
               ellipsis:true,
               customRender: (text, record) => {
                  let {pushJifenEd, pushJifenIng} = record
                  return (
                     <div>
                        <div>总积分：{text || 0}</div>
                        <div>已到账：{pushJifenEd || 0}</div>
                        <div>未到账：{pushJifenIng || 0}</div>
                     </div>
                  )
               }
            },
            {
               title: '小汇豆余额',
               dataIndex: 'beans',
               align: 'center',
               ellipsis:true,
               customRender: (text, record) => {
                  let {beansEd, beansIng} = record
                  return (
                     <div>
                        <div>总积分：{text || 0}</div>
                        <div>已到账：{beansEd || 0}</div>
                        <div>未到账：{beansIng || 0}</div>
                     </div>
                  )
               }
            },
            {
               title: '直属上级',
               dataIndex: 'pusherUserStr',
               align: 'center',
               ellipsis:true
            },
            {
               title: '直属上级uid',
               dataIndex: 'pusherUserUnionId',
               align: 'center',
               ellipsis:true,
            },
            {
               title: '是否强关系',
               dataIndex: 'nodeStatus',
               align: 'center',
               ellipsis:true,
               customRender: text => {
                  let textMap = {
                     '1': '弱关系',
                     '2': '强关系'
                  }
                  return textMap[text]
               }
            },
            {
               title: '操作',
               fixed: 'right',
               align: 'center',
               ellipsis: true,
               auth: ['userList:updateNodeStatus', 'userList:changeRecord'],
               scopedSlots: { customRender: 'options' }
            }
         ]),
         parentChangeColumns: [
            {
               title: '原直属上级',
               align: 'center',
               ellipsis:true,
               customRender: (_,record) => {
                  let {oldParentMobile, oldNodeStatusStr} = record
                  return `${oldParentMobile}(${oldNodeStatusStr})`
               }
            },
            {
               title: '变更后直属上级',
               align: 'center',
               ellipsis:true,
               customRender: (_,record) => {
                  let {parentMobileAfterchange, nodeStatusAfterchangeStr} = record
                  return `${parentMobileAfterchange}(${nodeStatusAfterchangeStr})`
               }
            },
            {
               title: '变更时间',
               dataIndex: 'createTimeStr',
               align: 'center',
               ellipsis:true
            },
         ],
         tableData:[],
         parentChangeData: [],
         pushTime: [],
         currentPushTime: [],
         activeTime: [],
         queryData:{
            mobile:undefined,
            userLevel:undefined,
            buyerId:undefined,
            unionId:undefined,
            parentUserId:undefined,
            activeStatus:undefined,
         },
         showTime:{
            format: 'HH:mm:ss',
            defaultValue:[moment('00:00:00', 'HH:mm:ss'),moment('23:59:59', 'HH:mm:ss')]
         },
      }
   },
   mounted () {
      this.loadTableData()
   },
   methods:{
      moment,
      handleParams () {
         let params = {...this.queryData,pageNum:this.pageNum,pageSize:this.pageSize}
         if (this.pushTime.length) {
            params.pushStartTime = this.pushTime[0].valueOf() / 1000
            params.pushEndTime = this.pushTime[1].valueOf() / 1000
         }
         if (this.currentPushTime.length) {
            params.currentPushStartTime = this.currentPushTime[0].valueOf() / 1000
            params.currentPushEndTime = this.currentPushTime[1].valueOf() / 1000
         }
         if (this.activeTime.length) {
            params.activeStartTime = this.activeTime[0].valueOf() / 1000
            params.activeEndTime = this.activeTime[1].valueOf() / 1000
         }
         for (const key in params) {
            if (!params[key]) {
               delete params[key]
            }
         }
         return params
      },
      loadTableData () {
         this.loading = true
         const params = this.handleParams()
         api.shopkeeperManage.userList(params).then(res => {
            if (res.code === '00000') {
               this.tableData = res.data.dataList
               if (Object.values(params).filter(i=>i).length === 2) {
                  //没有查询条件时只能展示50条
                  this.showTotal = res.data.total
                  this.total = 50
               } else {
                  this.showTotal = res.data.total
                  this.total = res.data.total
               }
               
            } else {
               this.$message.error(res.msg)
            }
         }).finally(() => this.loading = false)
      },
      unbind ({mobile,id,activeJifen,isActiveStatus,isOld,currentPushJifen,rewardJifen}) {
         let text = `确定要解绑手机号${mobile}的强关系吗?`;
         // isOld == 1 是平移用户   2是非平移用户
         // 如果用户有激活积分且不是已激活或者准激活  (1 是已激活和准激活)
         if(isOld == 1 && isActiveStatus == 0 ){
            let whatFen = '激活'
            let fen = 0
            if(rewardJifen > 0){
               whatFen = '奖励'
               fen = rewardJifen
               text = `手机号${mobile}有${fen}${whatFen}积分，确定要解绑的强关系吗?`
            }
            if(activeJifen > 0){
               whatFen = '激活'
               fen = activeJifen
               text = `手机号${mobile}有${fen}${whatFen}积分，确定要解绑的强关系吗?`
            }
            
         }
         if(isOld == 2){
            let whatFen = '推广'
            let fen = 0
            if(rewardJifen > 0){
               whatFen = '奖励'
               fen = rewardJifen
               text = `手机号${mobile}有${fen}${whatFen}积分，确定要解绑的强关系吗?`
            }
            if(currentPushJifen > 0){
               whatFen = '推广'
               fen = currentPushJifen
               text = `手机号${mobile}有${fen}${whatFen}积分，确定要解绑的强关系吗?`
            }
         }
         this.$confirm({
            content: text,
            centered: true,
            onOk: () => new Promise((resolve,reject) => {
               let p = {
                  userId: id,
                  account: this.$store.state.userInfo.account
               }
               api.shopkeeperManage.updateNodeStatus(p).then(res => {
                  if (res.code !== '00000') {
                     reject()
                     return this.$message.error(res.msg)
                  }
                  this.$message.success('操作成功')
                  this.loadTableData()
                  resolve()
               })
            })
         })
      },
      getChangeRecord ({id}) {
         api.shopkeeperManage.userParentNodeChangeRecord({userId: id}).then(res => {
            if (res.code !== '00000') return this.$message.error(res.msg)
            this.parentChangeData = res.data.dataList
         })
         this.parentChangeModal = true
      },
      exportData () {
         const params = this.handleParams()
         if (Object.keys(params).length === 2) {
            // 只有pageSize pageNum  没有其他筛选条件
            this.$message.info('请添加筛选条件再进行导出')
            return
         }
         this.$refs.exportModal.show()
      }
   }
}
</script>

<style scoped lang='less'>

</style>